import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';

const MenuItems = () => (
  <>
  <Link to="/user">
      <ListItemButton>
        <ListItemIcon>
          <SupervisorAccountRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </Link>
    {/* <Link to="/teacher">
      <ListItemButton>
        <ListItemIcon>
          <Groups2RoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Teachers" />
      </ListItemButton>
    </Link> */}
    <Link to="/payment">
      <ListItemButton>
        <ListItemIcon>
          {/* <ForumIcon /> */}
          <PaymentsTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Payments" />
      </ListItemButton>
    </Link>
    <Link to="/contact">
      <ListItemButton>
        <ListItemIcon>
          <AlternateEmailRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Contact Us" />
      </ListItemButton>
    </Link>
  </>
);

export default MenuItems
