import { useState } from 'react';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Alert,
  Modal,
  Box,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Cookies from 'universal-cookie';
// components
import Iconify from '../../components/iconify';
import { API_URL } from '../../api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from 'src/store/mainReducer';

// ----------------------------------------------------------------------
const cookies = new Cookies();

export default function LoginForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleClick = (event) => {
    event.preventDefault()
    setLoading(true);
    axios
      .post(`${API_URL}/api/auth/login_admin`, { email, password })
      .then((res) => {
        cookies.set('admin_token', res.data.token, {
          path: '/',
        });
        setLoading(false);
        dispatch(login(res.data.userInfo))
        // console.log(cookies.get("token"))
        navigate('/')
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(err?.response?.data?.message || 'Can\'t connect with server.');
        setOpen(true);
      });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FDEDED',
    boxShadow: 24,
    borderRadius: '5px',
  };

  return (
    <Box component='form' onSubmit={handleClick}>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" href="https://www.mathsdirectory.co.uk/forgot_password">
          Forgot password?
        </Link>
        {loading ? <CircularProgress/> : ''}
      </Stack>
      {open ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Alert severity="error">{error}</Alert>
          </Box>
        </Modal>
      ) : (
        ''
      )}
      <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Login
      </LoadingButton>
    </Box>
  );
}
