import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Button,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ContactListHead, ContactListToolbar } from '../../sections/@dashboard/contact';
import { API_URL } from '../../api';
import { DeleteModal } from './DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContacts } from 'src/store/data/dataAsyncThunks';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: true },
];

export default function ContactPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  
  const {contacts} = useSelector(state => state.data)

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const displayData = contacts.slice(rowsPerPage * page, rowsPerPage * (page + 1)).map(({ _id, fullName, email, createdAt }) => ({ _id, name: fullName, email, date: new Date(createdAt).toLocaleDateString('en-GB') })).filter(contact => contact?.name?.toLowerCase()?.indexOf(filterName?.toLowerCase()) !== -1).sort((a, b) => {
    const aValue = a[orderBy]
    const bValue = b[orderBy]
    return order === 'asc'
      ? aValue > bValue ? -1 : 1
      : aValue < bValue ? -1 : 1
  })

  const handleDeleteModal = (e, id) => {
    e?.stopPropagation()
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = displayData?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    event.stopPropagation()
    const selectedIndex = selected.indexOf(name);
    let newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(name);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Maths.max(0, (1 + page) * rowsPerPage - displayData?.length) : 0;

  const isNotFound = !displayData?.length && !!filterName;

  function detailsPage(id) {
    navigate(`/contact/details/${id}`);
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContacts())
}, [dispatch])

  return (
    <>
      <Helmet>
        <title> Contacts | Maths Teacher </title>
      </Helmet>

      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Contacts
          </Typography>
        </Stack>

        <Card sx={{ borderRadius: '12px' }}>
          <ContactListToolbar
            selected={selected}
            numSelected={selected?.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Scrollbar>
            <TableContainer sx={{ overflow: 'visible' }}>
              <Table>
                <ContactListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={displayData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {displayData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((contact, index) => {
                    const { _id, name, email, date } = contact;
                    const selectedContact = selected?.indexOf(_id) !== -1;

                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedContact}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedContact}
                            onChange={(event) => handleClick(event, _id)}
                            sx={{ mr: 4 }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ cursor: 'pointer', fontFamily: 'Open Sans', fontWeight: '600' }}
                          onClick={() => detailsPage(_id)}
                        >
                          {name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ cursor: 'pointer', fontFamily: 'Open Sans', fontWeight: '400' }}
                          onClick={() => detailsPage(_id)}
                        >
                          {email}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ cursor: 'pointer', fontFamily: 'Open Sans', fontWeight: '400' }}
                          onClick={() => detailsPage(_id)}
                        >
                          {date}
                        </TableCell>
                        <TableCell align="left">
                          <MenuItem onClick={(e) => handleDeleteModal(e, _id)} sx={{ color: 'error.main' }}>
                            <Iconify icon={'eva:trash-2-outline'} />
                          </MenuItem>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {displayData.length === 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} style={{ textAlign: "center" }}><em>No Contact</em></TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph sx={{ fontFamily: 'Open Sans' }}>
                            Not found
                          </Typography>

                          <Typography variant="body2" sx={{ fontFamily: 'Open Sans' }}>
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={contacts?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
        />
      </Container>
    </>
  );
}
